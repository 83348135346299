<template>
  <div>
    <div class="report-info-block">
      <div class="row">
        <div class="col-md-4 col-lg-5 my-auto">
          <div class="d-flex align-items-center ">
            <div>
              <v-avatar :src="agentPhoto(company.icon)" :size="66" rounded />
            </div>
            <div class="ms-3">
              <div class="company-name">
                {{ company.title }}
              </div>
              <div class="company-subtitle">
                {{ company.subTitle }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-7">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-10">
              <div class="row justify-content-end">
                <div
                  class="col-md-4 mt-3 mt-md-0"
                  v-if="tableFiltration.revenue === true"
                >
                  <div class="report-info border-green">
                    <div class="report-info_title">
                      {{ company.totalRevenue | money }}
                    </div>
                    <div class="report-info_subtitle">
                      Total Revenue Funded
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-4 mt-3 mt-md-0"
                  v-if="tableFiltration.units === true"
                >
                  <div class="report-info border-dark">
                    <div class="report-info_title">
                      {{ company.totalUnits }}
                    </div>
                    <div class="report-info_subtitle">
                      Total Units Funded
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-4 mt-3 mt-md-0"
                  v-if="tableFiltration.expense === true"
                >
                  <div class="report-info border-red">
                    <div class="report-info_title">
                      {{ company.totalExpense | money }}
                    </div>
                    <div class="report-info_subtitle">
                      Total Expense
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content p-normal">
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>{{ groupBy ? groupBy : 'Month' }}</th>
              <th>Report for</th>
              <th v-if="tableFiltration.revenue === true" class="color-red">
                Revenue Funded
              </th>
              <th v-if="tableFiltration.units === true">Units</th>
              <th v-if="tableFiltration.expense === true" class="color-green">
                Expense
              </th>
              <th v-if="tableFiltration.volumeFunded === true">
                Volume Funded
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="company.data.length === 0">
              <td colspan="6">
                <div class="modal-alert modal-alert_advice text-center">
                  No Records Found
                </div>
              </td>
            </tr>
            <tr v-for="(data, index) in company.data" :key="index">
              <td>
                <b>{{ data.groupBy ? data.groupBy : 'N/A' }}</b>
              </td>
              <td>{{ data.reportFor }}</td>
              <td v-if="tableFiltration.revenue === true">
                {{ data.revenue | money }}
              </td>
              <td v-if="tableFiltration.units === true">{{ data.units }}</td>
              <td v-if="tableFiltration.expense === true">
                {{ data.expense | money }}
              </td>
              <td v-if="tableFiltration.volumeFunded === true">
                {{ data.volume_funded | money }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportCompany",
  props: {
    tableFilter: Object,
    groupBy: String,
    data: Object
  },
  data() {
    return {
      tableFiltration: this.tableFilter,
      company: this.data
    };
  }
};
</script>

<style lang="scss" scoped>
table.table {
  tbody {
    tr {
      td {
        opacity: 0.8;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
        b {
          opacity: 0.8;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 21px;
        }
      }
    }
  }
}
.company-name {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.company-subtitle {
  opacity: 0.7;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.report-info-block {
  padding: 14px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #e7e8e8;
  .report-info {
    padding: 18px 24px;
    border-radius: 4px;
    &_title {
      color: #000000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: right;
      margin-bottom: 9px;
      @media screen and (max-width: 1200px) {
        font-size: 15px;
      }
    }
    &_subtitle {
      opacity: 0.7;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
      @media screen and (max-width: 1200px) {
        font-size: 11px;
      }
    }
  }
}
</style>
