<template>
  <div class="modal modal-center" id="modalRenameReport">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Rename
        </h3>
        <div class="modal-close" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="Close modal">
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container p-0 border-0 m-0">
          <div class="form-group full-width">
            <label for="fieldReportName">Report Name</label>
            <input type="text" id="fieldReportName" class="form-control w-100" v-model="name" v-input-filled:value="name">
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 d-flex justify-content-start">
        <button 
          class="btn btn-primary w-25" 
          @click="saveName" 
          :class="{disabled: name.length === 0 || loading}"
          :disabled="name.length === 0 || loading"
        >
          Save
        </button>
        <button class="btn btn-cancel"  @click="$emit('close')">
          Discard
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportRenameModal',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: this.report.name,
      loading: false
    }
  },
  methods: {
    saveName() {
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.name);
      this.$http
        .post(`/api/v1/reports/${this.report.id}/edit-title`, formData)
        .then(() => {
          this.$emit('save', this.name);
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
#modalRenameReport {
  .modal-block {
    max-width: 480px;
    width: 100%;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.07);
    margin: 250px auto;
    height: fit-content;
    .modal-header {
      position: initial;
      border: none;
      .modal-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        cursor: pointer;
        transition: all .3s;
        img {
          opacity: .7;
          width: 24px;
          height: 24px;
        }
        &:hover {
          background: #eaeaea;
        }
      }
    }
    .modal-body {
      padding: 10px 30px;
    }
    .modal-footer {
      position: initial;
      padding: 15px 30px;
    }
  }
}
.btn-primary {
  &.disabled {
    border-color: unset;
  }
}
</style>
