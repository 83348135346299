<template>
  <div>
    <div class="report-info-block">
      <div class="row">
        <div class="col-md-4 col-lg-5 my-auto">
          <div class="d-flex align-items-center ">
            <div>
              <v-avatar :size="66" :src="agentPhoto(agentData.avatar)" rounded />
            </div>
            <div class="ms-3">
              <div class="company-name">
                {{ agentData.name }}
              </div>
              <div class="company-subtitle">
                {{ agentData.position }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-7">
          <div class="row justify-content-end">
            <div class="col-md-2 mt-3 mt-md-0">
              <div class="report-info border-yellow">
                <div class="report-info_title"># {{ agentData.ranked }}</div>
                <div class="report-info_subtitle">
                  Ranked
                </div>
              </div>
            </div>
            <div
              class="col-md-3 mt-3 mt-md-0"
              v-if="tableFiltration.revenue === true"
            >
              <div class="report-info border-green">
                <div class="report-info_title">
                  {{ agentData.revenue | money }}
                </div>
                <div class="report-info_subtitle">
                  Total Revenue Funded
                </div>
              </div>
            </div>
            <div
              class="col-md-3 mt-3 mt-md-0"
              v-if="tableFiltration.expense === true"
            >
              <div class="report-info border-dark">
                <div class="report-info_title">
                  {{ agentData.units }}
                </div>
                <div class="report-info_subtitle">
                  Total Units Funded
                </div>
              </div>
            </div>
            <div
              class="col-md-3 mt-3 mt-md-0"
              v-if="tableFiltration.units === true"
            >
              <div class="report-info border-red">
                <div class="report-info_title">
                  {{ agentData.expense | money }}
                </div>
                <div class="report-info_subtitle">
                  Total Expense
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content p-normal">
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Report for</th>
              <th v-if="tableFiltration.revenue === true" class="color-red">
                Revenue Funded
              </th>
              <th v-if="tableFiltration.units === true">Units</th>
              <th v-if="tableFiltration.expense === true" class="color-green">
                Expense
              </th>
              <th v-if="tableFiltration.withdrawal">
                Withdrawal
              </th>
              <th v-if="tableFiltration.volumeFunded">
                Volume Funded
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="agentData.data.length === 0">
              <td colspan="6">
                <div class="modal-alert modal-alert_advice text-center">
                  No Records Found
                </div>
              </td>
            </tr>
            <tr v-for="(agent, index) in agentData.data" :key="index">
              <td>
                <b>{{ agent.month }}</b>
              </td>
              <td>{{ agent.reportFor }}</td>
              <td v-if="tableFiltration.revenue === true">
                {{ agent.revenue | money }}
              </td>
              <td v-if="tableFiltration.units === true">{{ agent.units }}</td>
              <td v-if="tableFiltration.expense === true">
                {{ agent.expense | money }}
              </td>
              <td v-if="tableFiltration.withdrawal">
                {{ agent.withdrawal | money }}
              </td>
              <td v-if="tableFiltration.volumeFunded">
                {{ agent.volume_funded | money }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportSingleAgent",
  props: {
    tableFilter: Object,
    agentData: Object
  },
  data() {
    return {
      tableFiltration: this.tableFilter
    };
  }
};
</script>

<style lang="scss" scoped>
.company-name {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.company-subtitle {
  opacity: 0.7;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.report-info-block {
  padding: 14px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #e7e8e8;
  .report-info {
    padding: 18px 24px;
    border-radius: 4px;
    &_title {
      color: #000000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: right;
      margin-bottom: 9px;
      @media screen and (max-width: 1200px) {
        font-size: 15px;
      }
    }
    &_subtitle {
      opacity: 0.7;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: right;
      @media screen and (max-width: 1200px) {
        font-size: 11px;
      }
    }
  }
}
</style>
