<template>
  <div class="page-content p-normal">
    <div class="table">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Processor</th>
            <th>Report for</th>
            <th v-if="tableFiltration.turnTime === true">Turn Time</th>
            <th v-if="tableFiltration.units === true">Units</th>
            <th v-if="tableFiltration.withdrawal">Withdrawal</th>
            <th v-if="tableFiltration.volumeFunded">Volume Funded</th>
          </tr>
        </thead>
        <tbody v-if="!isEmpty">
          <tr v-for="(processor, index) in data" :key="index">
            <td>{{ index | indexNumber }}</td>
            <td>
              <div class="d-flex align-items-center">
                <v-avatar
                  rounded
                  :size="36"
                  :src="agentPhoto(processor.avatar)"
                />
                <span
                  class="user-fullname ms-3"
                  @click="$emit('showProcessorReport', processor)"
                >
                  {{ processor.name }}
                </span>
              </div>
            </td>
            <td>{{ processor.reportFor }}</td>
            <td v-if="tableFiltration.turnTime === true">
              {{
                processor.turn_time > 0
                  ? "~ " + processor.turn_time + " days"
                  : "N/A"
              }}
            </td>
            <td v-if="tableFiltration.units === true">{{ processor.units }}</td>
            <td v-if="tableFiltration.withdrawal">
              {{ processor.withdrawal | money }}
            </td>
            <td v-if="tableFiltration.volumeFunded">
              {{ processor.volume_funded | money }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="modal-alert modal-alert_advice text-center" v-if="isEmpty">
        No Records Found
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportAllProcessors",
  props: {
    tableFilter: Object,
    selectedProcessor: Object,
    data: Array
  },
  data() {
    return {
      tableFiltration: this.tableFilter
    };
  },
  filters: {
    indexNumber(value) {
      value = parseInt(value) + 1;
      if (value.toString().length === 1) {
        return "0" + value;
      }
      return value;
    }
  },
  computed: {
    isEmpty() {
      return (
        (this.selectedProcessor.id !== 0 &&
          (this.data.length === 0 ||
            this.data.filter(processor => {
              processor.id === this.selectedProcessor.id;
            }).length === 0)) ||
        (this.data.length === 0 && this.selectedProcessor.id === 0)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.user-fullname {
  transition: all 0.3s;
  cursor: pointer;
  &:after {
    display: block;
    content: "";
    width: 0;
    height: 1px;
    background: #0a0a0a;
    transition: all 0.3s;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}
</style>
