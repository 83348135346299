<template>
  <div class="modal modal-center" id="modalRenameReport">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div>
        <div class="modal-header">
          <h3>
            Add name of this report
          </h3>
          <div class="modal-close" @click="$emit('close')">
            <img src="@/assets/icons/icon-remove.svg" alt="Close modal" />
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-body-container p-0 border-0 m-0">
            <div class="form-group full-width">
              <label for="fieldReportName">Report Name</label>
              <input
                type="text"
                id="fieldReportName"
                class="form-control w-100"
                v-model="reportName"
                v-input-filled:value="reportName"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 d-flex justify-content-start">
          <base-button title="Save" action="secondary-default" @click-btn="saveName" :disabled="!reportName" :loading="ui.saving" />
          <base-button title="Discard" action="secondary" @click-btn="$emit('close')" class="ms-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import BaseButton from '../../../components/BaseButton.vue';
export default {
  name: "AddReportName",
  components: { BaseButton },
  props: {
    type: String,
    groupBy: String,
    startDate: String,
    endDate: String,
    sortBy: String,
    orderBy: String,
    show: Object,
    processor: Object,
    agent: Object,
    loanType: Array
  },
  data() {
    return {
      reportName: "",
      ui: {
        saving: false
      }
    };
  },
  methods: {
    saveName() {
      this.ui.saving = true;
      let user_id = this.processor.id;
      if (user_id === 0) {
        user_id = this.agent.id;
      }

      let formData = new FormData();
      if (this.show.revenue === true) {
        formData.append("show[]", "revenue");
      }
      if (this.show.units === true) {
        formData.append("show[]", "units");
      }
      if (this.show.expense === true) {
        formData.append("show[]", "expense");
      }
      if (this.show.withdrawal === true) {
        formData.append("show[]", "withdrawal");
      }
      if (this.show.turnTime === true) {
        formData.append("show[]", "turnTime");
      }
      if (this.show.volumeFunded === true) {
        formData.append("show[]", "volumeFunded");
      }

      formData.append("loanType", JSON.stringify(this.loanType));
      formData.append("name", this.reportName.toLowerCase());
      formData.append("type", this.type.toLowerCase());
      formData.append("groupBy", this.groupBy.toLowerCase());
      formData.append("startDate", moment(String(this.startDate)).format('YYYY-MM-DD'));
      formData.append("endDate", moment(String(this.endDate)).format('YYYY-MM-DD'));
      formData.append("sortBy", this.sortBy.toLowerCase());
      formData.append("orderBy", this.orderBy.toLowerCase());
      formData.append("userId", user_id);

      this.$http
        .post(`/api/v1/reports/new`, formData)
        .then(() => {
          this.alertSuccess("New Report added succesfully!");
          setTimeout(() => {
            this.$emit('close')
            this.$router.replace({
              name: 'reports'
            })
          }, 1000)
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.saving = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#modalRenameReport {
  .modal-block {
    max-width: 480px;
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07);
    margin: 250px auto;
    height: fit-content;
    .modal-header {
      position: initial;
      border: none;
      .modal-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s;
        img {
          opacity: 0.7;
          width: 24px;
          height: 24px;
        }
        &:hover {
          background: #eaeaea;
        }
      }
    }
    .modal-body {
      padding: 10px 30px;
    }
    .modal-footer {
      position: initial;
      padding: 15px 30px;
    }
  }
}
.btn-primary {
  &.disabled {
    border-color: unset;
  }
}
</style>
