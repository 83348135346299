<template>
  <div class="page-content p-normal">
    <div class="table">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Agent</th>
            <th>Report for</th>
            <th v-if="tableFiltration.revenue" class="color-red">
              Revenue funded
            </th>
            <th v-if="tableFiltration.units">Units</th>
            <th v-if="tableFiltration.expense" class="color-green">
              Expense
            </th>
            <th v-if="tableFiltration.withdrawal">
              Withdrawal
            </th>
            <th v-if="tableFiltration.volumeFunded">
              Volume Funded
            </th>
          </tr>
        </thead>
        <tbody v-if="!isEmpty">
          <tr v-for="(agent, index) in data" :key="index">
            <td>{{ index | indexNumber }}</td>
            <td>
              <div class="d-flex align-items-center">
                <v-avatar rounded :size="36" :src="agentPhoto(agent.avatar)" />
                <span
                  class="user-fullname ms-3"
                  @click="$emit('showAgentReport', agent)"
                >
                  {{ agent.name }}
                </span>
              </div>
            </td>
            <td>
              {{ agent.reportFor }}
            </td>
            <td v-if="tableFiltration.revenue">
              {{ agent.revenue | money }}
            </td>
            <td v-if="tableFiltration.units">
              {{ agent.units }}
            </td>
            <td v-if="tableFiltration.expense">
              {{ agent.expense | money }}
            </td>
            <td v-if="tableFiltration.withdrawal">
              {{ agent.withdrawal | money }}
            </td>
            <td v-if="tableFiltration.volumeFunded">
              {{ agent.volume_funded | money }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="modal-alert modal-alert_advice text-center" v-if="isEmpty">
        No Records Found
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportAllAgent",
  props: {
    tableFilter: Object,
    selectedAgent: Object,
    data: Array
  },
  data() {
    return {
      tableFiltration: this.tableFilter
    };
  },
  filters: {
    indexNumber(value) {
      value = parseInt(value) + 1;
      if (value.toString().length === 1) {
        return "0" + value;
      }
      return value;
    }
  },
  computed: {
    isEmpty() {
      return (
        (this.selectedAgent.id !== 0 &&
          (this.data.length === 0 ||
            this.data.filter(agent => {
              agent.id === this.selectedAgent.id;
            }).length === 0)) ||
        (this.data.length === 0 && this.selectedAgent.id === 0)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.user-fullname {
  transition: all 0.3s;
  cursor: pointer;
  &:after {
    display: block;
    content: "";
    width: 0;
    height: 1px;
    background: #0a0a0a;
    transition: all 0.3s;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}
</style>
